import React, { useEffect, useState, useContext } from "react";
import SlidingProgressBar from "../slidingProgressBar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { CryptoDataContext } from "./../../../App";
import chains from "../../../data/chains.json";
import Telegram from "./../../../images/icon/telegram-icon.svg";
import Website from "./../../../images/icon/website.svg";
import Twitter from "./../../../images/icon/X-icon.svg";
import { handleError } from "../../../utils/errorHandler";
import { useNotifications } from "@toolpad/core";
import { formatCoinValue } from "../../../helpers/formatPrice";
import Sui from "./../../../images/icon/crypto/sui.png";

const CoinListing = () => {
  const notif = useNotifications();

  const types = {
    latest: "latestList",
    presales: "presales-price",
    gainers: "gainers",
  };
  const forLink = {
    "English Telegram": Telegram,
    "Chineese Telegram": Telegram,
    Website: Website,
    twitter: Twitter,
  };
  const forSocial = {
    ENGLISH_TELEGRAM: Telegram,
    TWITTER: Twitter,
  };
  const { type } = useParams();
  const { isLoggedInState, setCoinForDetail } = useContext(CryptoDataContext);
  const [coins, setCoins] = useState([]);
  const [category, setCategory] = useState(type);
  const [route, setRoute] = useState(types[type]);
  const [network, setNetwork] = useState("ether");
  const [sortBy, setSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const navigate = useNavigate();

  const renderPageButtons = () => {
    const pageButtons = [];

    if (currentPage > 4) {
      pageButtons.push(1);
    }

    // Show dots if needed
    if (currentPage > 4) {
      pageButtons.push("...");
    }

    // Show pages before the current page
    for (let i = Math.max(currentPage - 3, 1); i < currentPage; i++) {
      pageButtons.push(i);
    }

    // Show the current page
    pageButtons.push(currentPage);

    // Show pages after the current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + 3, totalPages);
      i++
    ) {
      pageButtons.push(i);
    }

    // Show dots if needed
    if (currentPage < totalPages - 3) {
      pageButtons.push("...");
    }

    //show last pages
    if (currentPage < totalPages - 3) {
      pageButtons.push(totalPages);
    }

    return pageButtons;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [votes, setVotes] = useState({});

  ////////////////////////////////////////////////////////////////////

  const [visibleSocialLink, setVisibleSocialLink] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleSocialButton = (divId) => {
    setIsVisible(!isVisible);
    setVisibleSocialLink(divId);
  };

  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  const updateVotes = async () => {
    if (localStorage.getItem("token")) {
      let itemIds = [];
      for (let i = 0; i < coins.length; i++) {
        itemIds.push(coins[i]._id);
      }
      try {
        const result = await axios.post(
          "https://fourtis.xyz/api/hasVotedBatch",
          {
            itemType: route == "presales-price" ? "presale" : "coin",
            itemIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setVotes(result.data.voteMap);
      } catch (e) {
        notif.show(handleError(e), {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleClickVoteButton = async (item, type) => {
    const token = localStorage.getItem("token");
    const upvote = votes[item._id];
    if (token) {
      try {
        const result = await axios.post(
          "https://fourtis.xyz/api/" + (upvote ? "unvote" : "vote"),
          {
            itemId: item._id,
            itemType: type,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        let newCoins = [...coins];
        const index = newCoins.findIndex((c) => c._id == item._id);
        if (upvote) {
          newCoins[index].votes -= 1;
        } else {
          newCoins[index].votes += 1;
        }
        setCoins(newCoins);
        updateVotes();
      } catch (e) {
        notif.show(handleError(e), {
          autoHideDuration: 3000,
          severity: "error",
        });
      }
    } else {
      notif.show("You need to be logged in!", {
        severity: "warning",
        autoHideDuration: 3000,
      });
    }
  };

  ///////////////////////////////////////

  useEffect(() => {
    axios.get(`https://fourtis.xyz/api/${route}?chain=${network}`).then((r) => {
      setCoins(r.data.data);
      setTotalPages(r.data.totalPages);
    });
  }, []);

  const onCategorykClicked = (category) => {
    const newRoute = types[category];
    if (newRoute) {
      const url =
        `https://fourtis.xyz/api/${newRoute}?chain=${network}&page=${currentPage}&pageSize=10` +
        (sortBy
          ? `&sortField=${sortBy}&sortOrder=${desc ? "desc" : "asc"}`
          : "");
      axios
        .get(url)
        .then((r) => {
          setCoins(r.data.data);
          setTotalPages(r.data.totalPages);
          setCategory(category);
          setRoute(newRoute);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const onNetworkClicked = (networkId) => {
    axios
      .get(
        `https://fourtis.xyz/api/${route}?chain=${networkId}&page=${currentPage}&pageSize=10` +
          (sortBy
            ? `&sortField=${sortBy}&sortOrder=${desc ? "desc" : "asc"}`
            : "")
      )
      .then((r) => {
        setCoins(r.data.data);
        setTotalPages(r.data.totalPages);
        setNetwork(networkId);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const onSortClicked = (sortBy, desc) => {
    axios
      .get(
        `https://fourtis.xyz/api/${route}?chain=${network}&page=${currentPage}&pageSize=10&sortField=${sortBy}&sortOrder=${
          desc ? "desc" : "asc"
        }`
      )
      .then((r) => {
        setCoins(r.data.data);
        setTotalPages(r.data.totalPages);
      });
    setSortBy(sortBy);
    setDesc(!desc);
  };

  const onPageClicked = (selectedPage) => {
    axios
      .get(
        `https://fourtis.xyz/api/${route}?chain=${network}&page=${selectedPage}` +
          (sortBy
            ? `&sortField=${sortBy}&sortOrder=${desc ? "desc" : "asc"}`
            : "")
      )
      .then((r) => {
        setCoins(r.data.data);
        setTotalPages(r.data.totalPages);
        setCurrentPage(selectedPage);
      });
  };

  const getTimeDifference = (launchDate) => {
    const now = new Date();
    const launch = new Date(launchDate);
    const diffInMs = launch - now; // Positive if in the future, negative if in the past

    // Determine if the launch date is in the future or past
    const isFuture = diffInMs > 0;

    // Use absolute value for calculation
    const absDiffInMs = Math.abs(diffInMs);

    // Calculate the differences
    const diffInSeconds = Math.floor(absDiffInMs / 1000);
    const diffInMinutes = Math.floor(absDiffInMs / (1000 * 60));
    const diffInHours = Math.floor(absDiffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(absDiffInMs / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(absDiffInMs / (1000 * 60 * 60 * 24 * 7));
    const diffInMonths = Math.floor(
      absDiffInMs / (1000 * 60 * 60 * 24 * 30.44)
    );
    const diffInYears = Math.floor(
      absDiffInMs / (1000 * 60 * 60 * 24 * 365.25)
    );

    // Choose the appropriate time unit and suffix
    let timeString = "";
    if (diffInYears > 0) {
      timeString = `${diffInYears} year${diffInYears > 1 ? "s" : ""}`;
    } else if (diffInMonths > 0) {
      timeString = `${diffInMonths} month${diffInMonths > 1 ? "s" : ""}`;
    } else if (diffInWeeks > 0) {
      timeString = `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""}`;
    } else if (diffInDays > 0) {
      timeString = `${diffInDays} day${diffInDays > 1 ? "s" : ""}`;
    } else if (diffInHours > 0) {
      timeString = `${diffInHours} hour${diffInHours > 1 ? "s" : ""}`;
    } else if (diffInMinutes > 0) {
      timeString = `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""}`;
    } else {
      timeString = `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""}`;
    }

    // Append the appropriate suffix
    const suffix = isFuture ? "remaining" : "ago";
    return `${timeString} ${suffix}`;
  };

  const getPresaleDating = (profile) => {
    if (!profile) return;
    const start = new Date(profile.start_date).getTime();
    const end = new Date(profile.end_date).getTime();
    const now = Date.now();
    if (start > now) {
      return "Upcoming";
    } else if (start < now && end > now) {
      return "Ongoing";
    } else {
      return "Ended";
    }
  };

  const handleBoostClick = (coin) => {
    const tweetText = `Let's take #${coin.name} ($${coin.symbol}) to the moon! 🚀 #memecoins`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&via=CoinIndexOfficial`;
    window.open(tweetUrl, "_blank");
  };

  useEffect(() => {
    if (coins.length > 0) {
      updateVotes();
    }
  }, [coins]);

  return (
    <>
      <SlidingProgressBar />
      <div className="dashboad-cntr">
        <div className="gnrl-category-cntr">
          <div
            className="gnrl-ctgry-cntr"
            style={{
              backgroundColor: category === "latest" ? "#3f444b" : "black",
              color: "white",
            }}
          >
            <label htmlFor="latest" className="gnr-ctgry-lbl">
              <input
                type="radio"
                id="latest"
                name="gnr-ctgry"
                className="gnr-ctgry-npt"
                value="latest"
                checked={category === "latest"}
                onClick={() => {
                  onCategorykClicked("latest");
                }}
              />
              Latest
            </label>
          </div>{" "}
          <div
            className="gnrl-ctgry-cntr"
            style={{
              backgroundColor: category === "presales" ? "#3f444b" : "black",
              color: "white",
            }}
          >
            <label htmlFor="presales" className="gnr-ctgry-lbl">
              <input
                type="radio"
                id="presales"
                name="gnr-ctgry"
                className="gnr-ctgry-npt"
                value="Presales"
                checked={category === "presales"}
                onClick={() => {
                  onCategorykClicked("presales");
                }}
              />
              Presales
            </label>
          </div>{" "}
          <div
            className="gnrl-ctgry-cntr"
            style={{
              backgroundColor: category === "gainers" ? "#3f444b" : "black",
              color: "white",
            }}
          >
            <label htmlFor="gainers" className="gnr-ctgry-lbl">
              <input
                type="radio"
                id="gainers"
                name="gnr-ctgry"
                className="gnr-ctgry-npt"
                value="gainers"
                checked={category === "gainers"}
                onClick={() => {
                  onCategorykClicked("gainers");
                }}
              />
              Gainers
            </label>
          </div>{" "}
        </div>
        <div className="crypto-category-cntr">
          {chains.data.map((CH, index) => {
            return (
              <div
                className="crypto-ctgry-cntr"
                key={index}
                style={{
                  backgroundImage:
                    network === CH.slug
                      ? "linear-gradient(to top right, rgb(30, 30, 30) 20%, #378E73)"
                      : "",
                  color: network === CH.slug ? "white" : "rgb(180, 180, 180)",
                }}
              >
                <label htmlFor={CH.symbol} className="crypto-ctgry-lbl">
                  <input
                    type="radio"
                    id={CH.symbol}
                    name="gnr-ctgry"
                    className="gnr-ctgry-npt"
                    value={CH.symbol}
                    checked={network === CH.slug}
                    onChange={() => onNetworkClicked(CH.slug)}
                  />
                  <img
                    src={CH.symbol === "sui" ? Sui : CH.image}
                    alt=""
                    className="crypto-category-img"
                  />
                  <span>{CH.symbol}</span>
                </label>
              </div>
            );
          })}
        </div>
        <div className="crypto-table-cntr">
          {category !== "Presales" ? (
            <table className="content-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    style={{
                      textAlign: "left",
                      position: "sticky",
                      left: "0",
                      zIndex: "150",
                    }}
                    onClick={() => setSortBy("coin")}
                  >
                    Coin
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "coin" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                  <th onClick={() => onSortClicked("h1", desc)}>
                    1H{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "h1" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                  <th onClick={() => onSortClicked("h6", desc)}>
                    6H{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "h6" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                  <th onClick={() => onSortClicked("h24", desc)}>
                    24H{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "h24" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                  <th onClick={() => onSortClicked("price", desc)}>
                    Price{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "price" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                  <th onClick={() => onSortClicked("marketcap", desc)}>
                    Marketcap{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "marketcap" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                  <th>Social</th>
                  <th>Links</th>
                  <th>Launch Date</th>
                  <th onClick={() => onSortClicked("votes", desc)}>
                    UpVote
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#378E73",
                        fontSize: "1.15rem",
                      }}
                    >
                      {sortBy !== "votes" ? (
                        <span>&#8645;</span> // ⇅ Dec character
                      ) : desc ? (
                        <span>&#8593;</span> // ↑ Dec character
                      ) : (
                        <span>&#8595;</span> // ↓ Dec character
                      )}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {coins.map((coin, index) => (
                  <tr key={coin._id}>
                    <td>
                      <svg
                        width="15px"
                        height="15px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" />

                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />

                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.1507 2.3649C10.8306 0.713558 13.1694 0.713567 13.8494 2.3649L16.1856 8.0386L21.4255 8.34683C23.2632 8.45493 23.9912 10.7786 22.5437 11.916L18.1816 15.3433L19.9202 20.2694C20.5648 22.0955 18.497 23.6802 16.9012 22.5831L12 19.2135L7.09881 22.5831C5.50303 23.6802 3.43525 22.0955 4.07977 20.2694L5.81838 15.3433L1.45635 11.916C0.0087955 10.7787 0.736801 8.45493 2.57454 8.34683L7.81442 8.0386L10.1507 2.3649ZM12 3.1264L9.18559 9.9614L2.69199 10.3434L8.18164 14.6567L5.96575 20.935L12 16.7865L18.0343 20.935L15.8184 14.6567L21.308 10.3434L14.8144 9.9614L12 3.1264Z"
                            fill="#b4b4b4"
                          />{" "}
                        </g>
                      </svg>
                      <button
                        className="navigate-btn"
                        onClick={() => {
                          setCoinForDetail(coin);
                          navigate(
                            "/token-details/coin/" +
                              coin.contract_details[0].chain +
                              "/" +
                              (coin.contract_details[0].contract_address ==
                              "TBA"
                                ? "id=" + coin._id
                                : coin.contract_details[0].contract_address)
                          );
                        }}
                      >
                        {" "}
                        Details
                      </button>
                    </td>
                    <td
                      style={{ position: "sticky", left: "0", zIndex: "150" }}
                      className="coin-column"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src={coin.logo_url}
                          alt={coin.name}
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "10px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        <img
                          src={
                            "https://fourtis.xyz/images/" +
                            chains.logos[coin.contract_details[0].chain]
                          }
                          alt={coin.name}
                          style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            position: "absolute",
                            top: "-5px",
                            left: "30px",
                          }}
                        />{" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {coin.symbol}
                          </span>
                          <span>{coin.name}</span>
                        </div>
                      </div>
                    </td>
                    <td
                      change={coin.variation1h || 0}
                      style={{
                        color:
                          parseFloat(coin.variation1h || 0) >= 0
                            ? "rgb(46, 246, 46)"
                            : "rgb(220, 16, 16)",
                      }}
                    >
                      {coin.variation1h ? formatCoinValue(coin.variation1h) : 0}
                      %
                    </td>
                    <td
                      change={coin.variation6h || 0}
                      style={{
                        color:
                          parseFloat(coin.variation6h || 0) >= 0
                            ? "rgb(46, 246, 46)"
                            : "rgb(220, 16, 16)",
                      }}
                    >
                      {coin.variation6h ? formatCoinValue(coin.variation6h) : 0}
                      %
                    </td>
                    <td
                      change={coin.variation24h || 0}
                      style={{
                        color:
                          parseFloat(coin.variation24h || 0) >= 0
                            ? "rgb(46, 246, 46)"
                            : "rgb(220, 16, 16)",
                      }}
                    >
                      {coin.variation24h
                        ? formatCoinValue(coin.variation24h)
                        : 0}
                      %
                    </td>
                    <td>$ {coin.price ? formatCoinValue(coin.price) : 0}</td>
                    <td>$ {parseInt(coin.mcap || 0)}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "5px 0 5px 10px",
                          margin: "0",
                        }}
                      >
                        {coin.links.map((link, index) => {
                          return (
                            forSocial[link.title] && (
                              <a href={link.url} className="social-link-a">
                                <img
                                  src={forSocial[link.title]}
                                  alt=""
                                  className="social-link-img"
                                />
                                <span>{link.social_count || 0}</span>
                              </a>
                            )
                          );
                        })}
                      </div>
                    </td>
                    <td style={{ position: "relative" }}>
                      <button
                        onClick={() => handleSocialButton(index)}
                        className="social-link-btn"
                      >
                        🔗
                      </button>
                      {visibleSocialLink === index && isVisible && (
                        <div
                          className={"social-link-cntr" + index}
                          key={"social-link" + index}
                        >
                          {coin.links.map(
                            (link, idx) =>
                              forLink[link.title] && (
                                <div className="social-link" key={idx}>
                                  <a href={link.url} className="social-link-a">
                                    <img
                                      src={forLink[link.title]}
                                      alt=""
                                      className="social-link-img"
                                    />
                                    <span>{link.title}</span>{" "}
                                  </a>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </td>
                    <td>{getTimeDifference(coin.launch_date)}</td>
                    <td>
                      <button
                        className={`vote-input${coin._id} vote-input`}
                        onClick={() => {
                          handleClickVoteButton(coin, "coin");
                        }}
                        key={coin._id}
                        id={coin._id}
                        // style={{
                        //   background: `linear-gradient(to right, ${
                        //     coin.voting.vote <= 25
                        //       ? "#741e1e"
                        //       : coin.voting.vote > 25 && coin.voting.vote <= 60
                        //       ? "#3B884B"
                        //       : coin.voting.vote > 60
                        //       ? "#46DE4E"
                        //       : "#123d65"
                        //   } ${coin.voting.vote}%, rgb(15, 15, 15) ${
                        //     coin.voting.vote
                        //   }%)`,
                        //   background: `linear-gradient(to right, green ${fillPercentage}%, rgb(15, 15, 15) ${fillPercentage}%)`,
                        // }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-rocket"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          stroke="#fff"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"></path>
                          <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"></path>
                          <circle cx="15" cy="9" r="1"></circle>
                        </svg>
                        <span>{votes[coin._id] ? "Unvote" : "Vote"}</span>
                      </button>
                      <button
                        className={`Boost-input${coin._id} Boost-input`}
                        // style={{
                        //   background: `linear-gradient(to right, ${
                        //     coin.voting.boost <= 25
                        //       ? "#741e1e"
                        //       : coin.voting.boost > 25 && coin.voting.boost <= 60
                        //       ? "#3B4663"
                        //       : coin.voting.boost > 60
                        //       ? "#1946B7"
                        //       : "#123d65"
                        //   } ${coin.voting.boost}%, rgb(15, 15, 15) ${
                        //     coin.voting.boost
                        //   }%)`,
                        //   /* background: `linear-gradient(to right, green ${fillPercentage}%, rgb(15, 15, 15) ${fillPercentage}%)`,*/
                        // }}
                        onClick={() => handleBoostClick(coin)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="tabler-icon tabler-icon-brand-x"
                        >
                          <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                          <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                        </svg>
                        Boost
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Coin</th>
                  <th>Platform</th>
                  <th>Contributed</th>
                  <th>Soft Cap/Hard Cap</th>
                  <th>Social</th>
                  <th>Links</th>
                  <th>Presale Date</th>
                  <th>Upvotes</th>
                </tr>
              </thead>
              <tbody>
                {coins.map((coin, index) => (
                  <tr key={coin._id}>
                    <td>
                      <svg
                        width="15px"
                        height="15px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" />

                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />

                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.1507 2.3649C10.8306 0.713558 13.1694 0.713567 13.8494 2.3649L16.1856 8.0386L21.4255 8.34683C23.2632 8.45493 23.9912 10.7786 22.5437 11.916L18.1816 15.3433L19.9202 20.2694C20.5648 22.0955 18.497 23.6802 16.9012 22.5831L12 19.2135L7.09881 22.5831C5.50303 23.6802 3.43525 22.0955 4.07977 20.2694L5.81838 15.3433L1.45635 11.916C0.0087955 10.7787 0.736801 8.45493 2.57454 8.34683L7.81442 8.0386L10.1507 2.3649ZM12 3.1264L9.18559 9.9614L2.69199 10.3434L8.18164 14.6567L5.96575 20.935L12 16.7865L18.0343 20.935L15.8184 14.6567L21.308 10.3434L14.8144 9.9614L12 3.1264Z"
                            fill="#b4b4b4"
                          />{" "}
                        </g>
                      </svg>
                      <button
                        className="navigate-btn"
                        onClick={() => {
                          setCoinForDetail(coin);
                          navigate(
                            "/token-details/presale/" +
                              coin.contract_details[0].chain +
                              "/" +
                              (coin.contract_details[0].contract_address ==
                              "TBA"
                                ? "id=" + coin._id
                                : coin.contract_details[0].contract_address)
                          );
                        }}
                      >
                        {" "}
                        Details{" "}
                      </button>
                    </td>
                    <td
                      style={{ position: "sticky", left: "0", zIndex: "150" }}
                      className="coin-column"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src={coin.logo_url}
                          alt={coin.name}
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "10px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        <img
                          src={
                            "https://fourtis.xyz/api" +
                            chains.logos[coin.contract_details[0].chain]
                          }
                          alt={coin.name}
                          style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            position: "absolute",
                            top: "-5px",
                            left: "30px",
                          }}
                        />{" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {coin.symbol}
                          </span>
                          <span>{coin.name}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <img
                        src={
                          coin.presale_platform
                            ? coin.presale_platform.image
                            : ""
                        }
                        alt="Pinksale"
                        style={{ width: "30px" }}
                      />{" "}
                      {/* Replace with actual logo URL */}
                      <span>
                        {coin.presale_platform
                          ? coin.presale_platform.name
                          : ""}
                      </span>
                    </td>
                    <td>-</td>
                    <td>
                      {coin.presale_details.soft_cap}{" "}
                      {coin.contract_details[0].chain} /{" "}
                      {coin.presale_details.hard_cap}{" "}
                      {coin.contract_details[0].chain}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "5px 0",
                          margin: "0",
                        }}
                      >
                        {coin.links.map((link, index) => {
                          return (
                            forSocial[link.title] && (
                              <a href={link.url} className="social-link-a">
                                <img
                                  src={forSocial[link.title]}
                                  alt=""
                                  className="social-link-img"
                                />
                                <span>{link.social_count || 0}</span>
                              </a>
                            )
                          );
                        })}
                      </div>
                    </td>
                    <td style={{ position: "relative" }}>
                      <button
                        onClick={() => handleSocialButton(index)}
                        className="social-link-btn"
                      >
                        🔗
                      </button>
                      {visibleSocialLink === index && isVisible && (
                        <div className="social-link-cntr">
                          {coin.links.map(
                            (link, idx) =>
                              forLink[link.title] && (
                                <div className="social-link" key={idx}>
                                  <a href={link.url} className="social-link-a">
                                    <img
                                      src={forLink[link.title]}
                                      alt=""
                                      className="social-link-img"
                                    />
                                    <span>{link.title}</span>{" "}
                                  </a>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </td>
                    <td>{getPresaleDating(coin.presale_details)}</td>
                    <td>
                      <button
                        className={`vote-input${coin._id} vote-input`}
                        onClick={() => {
                          handleClickVoteButton(coin, "presale");
                        }}
                        key={coin._id}
                        id={coin._id}
                        // style={{
                        //   background: `linear-gradient(to right, ${
                        //     coin.voting.vote <= 25
                        //       ? "#741e1e"
                        //       : coin.voting.vote > 25 && coin.voting.vote <= 60
                        //       ? "#3B884B"
                        //       : coin.voting.vote > 60
                        //       ? "#46DE4E"
                        //       : "#123d65"
                        //   } ${coin.voting.vote}%, rgb(15, 15, 15) ${
                        //     coin.voting.vote
                        //   }%)`,
                        //   background: `linear-gradient(to right, green ${fillPercentage}%, rgb(15, 15, 15) ${fillPercentage}%)`,
                        // }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-rocket"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          stroke="#fff"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"></path>
                          <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"></path>
                          <circle cx="15" cy="9" r="1"></circle>
                        </svg>
                        <span>{votes[coin._id] ? "Unvote" : "Vote"}</span>
                      </button>
                      <button
                        className={`Boost-input${coin._id} Boost-input`}
                        // style={{
                        //   background: `linear-gradient(to right, ${
                        //     coin.voting.boost <= 25
                        //       ? "#741e1e"
                        //       : coin.voting.boost > 25 && coin.voting.boost <= 60
                        //       ? "#3B4663"
                        //       : coin.voting.boost > 60
                        //       ? "#1946B7"
                        //       : "#123d65"
                        //   } ${coin.voting.boost}%, rgb(15, 15, 15) ${
                        //     coin.voting.boost
                        //   }%)`,
                        //   /* background: `linear-gradient(to right, green ${fillPercentage}%, rgb(15, 15, 15) ${fillPercentage}%)`,*/
                        // }}
                        onClick={() => handleBoostClick()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="tabler-icon tabler-icon-brand-x"
                        >
                          <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                          <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                        </svg>
                        Boost
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="pagination">
          <button
            onClick={() => onPageClicked(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              cursor: currentPage === 1 ? "not-allowed" : "pointer",
              opacity: currentPage === 1 ? "0.5" : "1",
            }}
          >
            Previous
          </button>
          {renderPageButtons().map((item, index) => {
            if (item === "...") {
              return (
                <span key={index} className="dots">
                  ...
                </span>
              );
            }
            return (
              <button
                key={index}
                onClick={() => onPageClicked(item)}
                className={item === currentPage ? "active" : ""}
              >
                {item}
              </button>
            );
          })}
          <button
            onClick={() => onPageClicked(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              opacity: currentPage === totalPages ? "0.5" : "1",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CoinListing;
