import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CryptoDataContext } from "./../../App";
import axios from "axios";
import chains from "../../data/chains.json";
import "./../../styles/micro/cryptoTile.css";
import { formatCoinValue } from "../../helpers/formatPrice";
import Sui from "./../../images/icon/crypto/sui.png";

const CryptoTile = () => {
  const BASE_LOGO = "https://dd.dexscreener.com/ds-data/chains/base.png";
  const [trendingData, setTrendingData] = useState([]);

  const networks = ["bsc", "ether", "solana", "tron", "base", "alt", "sui"];

  const navigate = useNavigate();
  const { setCoinForDetail } = useContext(CryptoDataContext);

  useEffect(() => {
    async function getTrending() {
      let data = {};
      for (let i = 0; i < networks.length; i++) {
        const url =
          networks[i] === "alt"
            ? "https://fourtis.xyz/api/trending"
            : "https://fourtis.xyz/api/trending?chain=" + networks[i];
        try {
          const result = await axios.get(url);
          data[networks[i]] = result.data.data.slice(
            0,
            Math.min(result.data.data.length, 10)
          );
        } catch (error) {
          console.error(`Error fetching data for ${networks[i]}:`, error);
          data[networks[i]] = []; // Assign empty array on error
        }
      }
      return data;
    }
    getTrending().then((r) => {
      setTrendingData(r);
    });
  }, []);

  // Function to map tier to CSS class
  const getTierClass = (tier) => {
    switch (tier?.toUpperCase()) {
      case "DIAMOND":
        return "tier-diamond";
      case "GOLD":
        return "tier-gold";
      case "PLATINUM":
        return "tier-platinum";
      case "PLANTINUM":
        return "tier-platinum";
      case "SILVER":
        return "tier-silver";
      case "BRONZE":
        return "tier-bronze";
      default:
        return "tier-unranked";
    }
  };

  // Function to get tier badge (optional)
  const getTierBadge = (tier) => {
    if (!tier) return null;
    const tierUpper = tier.toUpperCase();
    return (
      <span className={`tier-badge tier-${tierUpper.toLowerCase()}`}>
        {tierUpper}
      </span>
    );
  };

  return (
    <>
      <div>
        <h3 className="title-tile">
          <a href="#" className="title-tile-link" style={{ color: "#378e73" }}>
            Trending Coins (24H)
          </a>
        </h3>
      </div>
      <div className="crypto-tile-container">
        {networks.map((network, index) => {
          return (
            <div
              className="crypto-tile-block-container"
              key={"trending" + index}
            >
              <div className="tile-block">
                <div className="items-hdr">
                  <div className="items-hdr-img-container">
                    <img
                      src={
                        network === "base"
                          ? BASE_LOGO
                          : network === "sui"
                            ? Sui
                            : "https://fourtis.xyz/images/" +
                              chains.logos[network]
                      }
                      alt={`${network} logo`}
                      className="items-hdr-img"
                    />
                  </div>
                  <h2 className="items-hdr-title">{network.toUpperCase()}</h2>
                </div>
                <div
                  className="tile-container"
                  style={{ backgroundColor: "#0c0c0c" }}
                >
                  {trendingData[network] &&
                    trendingData[network].map((token, index) => {
                      return (
                        // Unique key for each token
                        <div
                          className={`tile-item-large-container ${getTierClass(
                            token.tier
                          )}`}
                          key={`token-${network}-${index}`}
                          onClick={() => {
                            navigate(
                              "/token-details/coin/" +
                                token.contract_details[0].chain +
                                "/" +
                                (token.contract_details[0].contract_address ===
                                "TBA"
                                  ? "id=" + token._id
                                  : token.contract_details[0].contract_address)
                            );
                          }}
                        >
                          <div className="tile-item-link">
                            <div className="tile-item-container">
                              <div className="tile-img-container">
                                <img
                                  src={token.logo_url || BASE_LOGO}
                                  alt={token.name}
                                  className="tile-img"
                                />
                              </div>
                              <div className="tile-name-info-oscltn">
                                <div className="tile-name-info">
                                  <div className="tile-name">
                                    {token.symbol}
                                  </div>
                                  <div className="tile-info">{token.name}</div>
                                </div>
                                <div className="tile-oscltn-h1">
                                  <span> h1/ </span>
                                  <span
                                    style={{
                                      color:
                                        parseFloat(token.variation1h) >= 0
                                          ? "#2ef62e" // Bright green
                                          : "#dc1010", // Bright red
                                    }}
                                  >
                                    {" "}
                                    {formatCoinValue(token.variation1h)}%{" "}
                                  </span>
                                </div>
                                <div
                                  className="tile-oscltn"
                                  style={{
                                    color:
                                      parseFloat(token.variation24h) >= 0
                                        ? "#2ef62e"
                                        : "#dc1010",
                                  }}
                                >
                                  {token.variation24h
                                    ? formatCoinValue(token.variation24h)
                                    : 0}
                                  %
                                </div>
                              </div>
                              {/* Tier Badge */}
                              {getTierBadge(token.tier)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CryptoTile;
